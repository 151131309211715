import { lazy, useCallback } from 'react';

import { useModal } from '~/components/modal';
import { useNotification } from '~/components/notification';
import { useErrorNotification } from '~/components/notification';
import { useFlags } from '~/utils/feature-flags';
import { useLazyQuery, useMutation } from '~/utils/graphql';

import { CREATE_PLAYLIST_MUTATION } from '~/queries/create-playlist-mutation';
import { GET_ANSWER_QUERY } from '~/queries/get-answer-query';
import { GET_VOUCH_ANSWERS_CROPPING_QUERY } from '~/queries/get-vouch-answers-cropping-query';

const AddFromLibraryModal = lazy(() => import('~/features/playlists/modals/AddFromLibraryModal'));

type Config = {
  handleSetCoverVideo: (result: { id?: string; createdPlaylistId: string }) => Promise<any>;
};

function useAddCoverVideoFromLibrary({ handleSetCoverVideo }: Config) {
  const notification = useNotification();
  const errorNotification = useErrorNotification();

  const flags = useFlags(['answer-vouches']);
  const answerVouchesEnabled = flags?.['answer-vouches']?.enabled;

  const { open } = useModal(AddFromLibraryModal);

  const [fetchVouch] = useLazyQuery(GET_VOUCH_ANSWERS_CROPPING_QUERY);
  const [fetchAnswer] = useLazyQuery(GET_ANSWER_QUERY);
  const [createPlaylist] = useMutation(CREATE_PLAYLIST_MUTATION);

  const handleChooseCoverVideoSubmit = useCallback(
    async ({ selected, id, playlistName }: { selected: string[]; id?: string; playlistName: string }) => {
      try {
        let answers;
        if (answerVouchesEnabled) {
          const res = await Promise.all(selected.map((id) => fetchVouch({ variables: { id } })));
          const items = res.map((r) => r.data?.vouch);
          answers = items.flatMap((item) => {
            const questions = item?.questions?.items;
            return questions?.map((question: any) => ({
              id: question?.answer?.id,
              settings: question?.answer?.settings
            }));
          });
        } else {
          // TS is not recognising the VideoAnswer fragment in the query, so have to any the result
          const res = await Promise.all(selected.map((id) => fetchAnswer({ variables: { id } }) as any));
          const items = res.map((r) => r.data?.answer);
          answers = items.map((item) => ({ id: item?.id, settings: item?.settings }));
        }

        const items = answers.map((answer) => ({
          id: answer?.id,
          settings: {
            endOffset: answer?.settings?.endOffset,
            startOffset: answer?.settings?.startOffset,
            crop: {
              x: answer?.settings?.crop?.x,
              y: answer?.settings?.crop?.y,
              width: answer?.settings?.crop?.width,
              height: answer?.settings?.crop?.height
            }
          }
        }));

        const createResult = await createPlaylist({
          variables: {
            name: playlistName,
            type: 'COVER_PLAYLIST',
            items
          }
        });

        if (!createResult.data?.createPlaylist?.id) {
          throw new Error('Cover video could not be set');
        }

        await handleSetCoverVideo({ id, createdPlaylistId: createResult.data.createPlaylist.id });
        notification.show({ message: 'Cover video successfully set.' });
      } catch (e) {
        errorNotification.show(e);
      }
    },
    [
      createPlaylist,
      errorNotification,
      handleSetCoverVideo,
      notification,
      answerVouchesEnabled,
      fetchVouch,
      fetchAnswer
    ]
  );

  return ({ id, playlistName }: { id?: string; playlistName: string }) => {
    open({
      title: 'Search your library to add a cover video',
      submitLabel: 'Add as cover video',
      onSubmit: ({ selected }) => handleChooseCoverVideoSubmit({ selected, id, playlistName })
    });
  };
}

export { useAddCoverVideoFromLibrary };
