import { useMemo } from 'react';

import { Button } from '~/components/button';
import { Field, Form, Fieldset, useForm } from '~/components/form';
import { Modal } from '~/components/modal';
import { useErrorNotification, useNotification } from '~/components/notification';
import { TextInput } from '~/components/text-input';
import { useAuth } from '~/utils/auth';
import { useMutation } from '~/utils/graphql';

import { UPDATE_ENTITY_MUTATION } from '~/queries/update-entity-mutation';

import type { ModalPassthroughProps } from '~/components/modal';

function RenameWorkspaceModal({ close }: ModalPassthroughProps) {
  const auth = useAuth();
  const workspaceName = useMemo(() => auth?.entity?.name, [auth]);

  const notification = useNotification();
  const errorNotification = useErrorNotification();

  const [updateEntity] = useMutation<any>(UPDATE_ENTITY_MUTATION, {
    refetchQueries: ['GetEntity', 'GetEntitySettings', 'GetEntityByHash', 'GetEntities']
  });

  const form = useForm({
    id: 'rename-workspace-form',
    defaultValues: {
      name: workspaceName
    },
    onSubmit: async (values) => {
      try {
        await updateEntity({
          variables: {
            input: {
              id: auth?.entity?.id,
              name: values.name
            }
          }
        });
        await auth?.refresh?.();
        notification.show({ message: 'Space renamed successfully' });
        close();
      } catch (e) {
        errorNotification.show(e);
      }
    }
  });

  return (
    <Modal
      id="rename-workspace-modal"
      onClose={close}
      title="Rename your Space"
      actions={
        <Button color="brand" form={form.id} loading={form.formState.isSubmitting}>
          Rename Space
        </Button>
      }
    >
      <Form form={form}>
        <Fieldset>
          <Field name="name" label="Space name" Input={TextInput} required />
        </Fieldset>
      </Form>
    </Modal>
  );
}

export { RenameWorkspaceModal };
