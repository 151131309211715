import { lazy, useCallback } from 'react';

import { useModal } from '~/components/modal';
import { useErrorNotification, useNotification } from '~/components/notification';
import { useMutation } from '~/utils/graphql';
import { useRouter } from '~/utils/routing/useRouter';

import { APPEND_PLAYLIST_MUTATION } from '~/queries/append-playlist-mutation';

const UploadVideoModal = lazy(() => import('~/features/global/modals/UploadVideoModal'));

function useUploadVideo() {
  const router = useRouter();
  const notification = useNotification();
  const errorNotification = useErrorNotification();

  const uploadVideoModal = useModal(UploadVideoModal);

  const [appendPlaylist] = useMutation(APPEND_PLAYLIST_MUTATION, { refetchQueries: ['GetPlaylists', 'GetVouch'] });

  return useCallback(
    ({ id, redirect = true }: { id: string; redirect?: boolean }) => {
      uploadVideoModal.open({
        redirect: false,
        onSubmit: async ({ vouch }) => {
          try {
            await appendPlaylist({
              variables: {
                id,
                items: [
                  {
                    id: vouch.questions?.items?.[0]?.answer?.id
                  }
                ]
              }
            });
            if (redirect) {
              await router.push(`/dashboard/playlists/${id}`);
            }
            notification.show({ message: 'Video successfully added to playlist.' });
          } catch (e) {
            errorNotification.show(e);
          }
        }
      });
    },
    [router, uploadVideoModal, notification, errorNotification, appendPlaylist]
  );
}

export { useUploadVideo };
