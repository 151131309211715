import type { Account, Application, Entity, Maybe } from '~/utils/codegen/graphql';

type Identity = {
  userId: string;
  providerName?: string;
  providerType?: string;
  issuer?: string;
  primary?: boolean;
  dateCreated: number;
};

type MappedIntegrations = {
  [key: string]: Maybe<RecursivePartial<Application>>;
};

type MapAuthArgs = {
  auth?: any;
  account?: Maybe<RecursivePartial<Account>>;
  entity?: Maybe<RecursivePartial<Entity>>;
  entities?: Maybe<{ items?: Maybe<RecursivePartial<Entity>>[] }>;
  featureFlags?: any;
};

function mapAuthData({ auth, account, entity, entities, featureFlags }: MapAuthArgs) {
  return {
    username: auth?.username,
    account,
    entity,
    entities,
    featureFlags,
    identities: JSON.parse(auth?.attributes?.identities || '[]') as Identity[],
    idToken: auth?.signInUserSession?.idToken?.payload || {},
    storage: {
      username: auth?.username,
      clockDrift: auth?.signInUserSession.clockDrift,
      accessToken: auth?.signInUserSession.accessToken?.jwtToken,
      refreshToken: auth?.signInUserSession.refreshToken?.token,
      idToken: auth?.signInUserSession.idToken?.jwtToken
    }
  };
}

export { mapAuthData };
export type { Identity, MappedIntegrations };
